/* eslint-disable import/no-relative-parent-imports */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-default-export */
import React from "react";
import { graphql } from "gatsby";
import { Page } from "@/components/Page";
import * as styles from "./template.module.css";

export default function Template({ data, location }: any) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    // page contains sidebar
    <Page variation="docs" location={location}>
      <div className={styles.docsWrapper}>
        <h1 className={styles.docsTitle}>{frontmatter.title}</h1>
        <div
          className={styles.docMarkDown}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Page>
  );
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
